import React from 'react';
import { Circle } from 'react-feather';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="flex flex-col md:flex-row text-xs items-center justify-between p-4 md:px-20">
      <div className="pb-2 text-gray-800 text-center flex flex-row space-x-2 items-center">
        <Link to="/">Terms and Conditions</Link> <Circle className="h-1" />{' '}
        <Link to="/">Privacy Policy</Link>
      </div>
      <div className="pb-2 text-gray-800 text-center">
        LandLoodi © All Rights Reserved 2023
      </div>
    </footer>
  );
};
