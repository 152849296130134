import React from 'react';
import { MainLayout } from 'components/layout';

export const Pricing = () => {
  return (
    <MainLayout>
      <div className="mt-12">
        <div className="text-md text-center font-small mb-4">Pricing</div>
        <div className="text-4xl text-center font-semibold mb-12 text-orange-300">
          Two plans, two prices. <br />
          No setup costs or any other hidden fees.
        </div>
        <div className="grid grid-cols-1 items-start md:grid-cols-3 gap-12">
          <div className="text-3xl font-small">Pick your plan.</div>
          <div className="mb-4 rounded-md bg-blue-100 flex flex-col space-y-12 items-start p-8 shadow md:h-96">
            <h3 className="text-2xl font-semibold mb-2">Individual Plan</h3>
            <p className="text-gray-600 mb-4 h-32">
              Ideal for independent landlords or small property owners who want
              to manage up to 5 properties with ease.
            </p>
            <p className="text-gray-600 bg-blue-200 p-8 w-full rounded-md">
              80,000 ugx /month
            </p>
          </div>
          <div className="mb-4 rounded-md bg-green-100 flex flex-col space-y-12 items-start p-8 shadow md:h-96">
            <h3 className="text-2xl font-semibold mb-2">Company Plan</h3>
            <p className="text-gray-600 mb-4 h-32">
              Perfect for property management companies or larger landlords who
              need to handle an unlimited number of properties effortlessly.
            </p>
            <p className="text-gray-600 bg-green-200 p-8 w-full rounded-md">
              180,000 ugx/month
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
