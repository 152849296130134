import React from 'react';
import Logo from 'assets/landloodi.svg';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export const Header = () => {
  const handleJoinWaitlist = () => {
    Swal.fire('Stay tuned', 'The waitlist will soon be available.');
  };

  return (
    <nav className="flex items-center justify-between p-4 md:px-20">
      <div>
        <img src={Logo} alt="Logo" className="h-8" />
      </div>
      {/* <div className="hidden md:flex space-x-4">
        <Link
          to="/pricing"
          className="text-gray-600 uppercase md:mr-12 hover:text-gray-900 transition duration-300"
        >
          Pricing
        </Link>
        <Link
          to="/about"
          className="text-gray-600 uppercase md:mr-12 hover:text-gray-900 transition duration-300"
        >
          About
        </Link>
      </div> */}
      <div>
        <button
          onClick={handleJoinWaitlist}
          className="bg-orange-600 text-white px-3 md:px-8 py-4 whitespace-nowrap uppercase text-xs rounded-md"
        >
          Join Waitlist
        </button>
      </div>
      <div className="md:hidden flex items-center">
        <button className="text-gray-600 hover:text-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};
