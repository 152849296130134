import React, { useEffect } from 'react';
import { MainLayout } from 'components/layout';
import storage from 'utils/storage';
import HouseSearchingBro from "assets/House-searching-bro.svg"
import Swal from 'sweetalert2';

export const Landing = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    storage.clearStorage();
  }, []);

  const handleJoinWaitlist = () => {
    Swal.fire('Stay tuned', 'The waitlist will soon be available.');
  };
  return (
    <>
      <MainLayout>
        <div className="flex flex-col h-screen justify-center items-center">
          <div className="text-center mb-4">
            <div className="text-xl">
              <span className="md:tracking-widest font-light text-center">
                DISCOVER A NEW ERA OF
              </span>
            </div>
            <div className="text-4xl md:text-5xl font-bold text-center">
              Convenience
            </div>
          </div>
          <button
            onClick={handleJoinWaitlist}
            className="bg-orange-600 text-white px-3 md:px-8 py-2 whitespace-nowrap uppercase text-xs rounded-md"
          >
            Join Waitlist
          </button>
        </div>
      </MainLayout>
    </>
  );
};
