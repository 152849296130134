import { lazyImport } from 'utils/lazyImport';

const { ContactUsRoutes } = lazyImport(
  () => import('features/contact'),
  'ContactUsRoutes'
);

const { WaitlistRoutes } = lazyImport(
  () => import('features/waitlist'),
  'WaitlistRoutes'
);

const { BlogRoutes } = lazyImport(() => import('features/blog'), 'BlogRoutes');

export const publicRoutes = [
  {
    path: '/contact-us/*',
    element: <ContactUsRoutes />,
  },
  {
    path: '/waitlist/*',
    element: <WaitlistRoutes />,
  },
  {
    path: '/blog/*',
    element: <BlogRoutes />,
  },
];
