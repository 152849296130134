import React from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

export const MainLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="mx-4 md:mx-20">{children}</div>
      <Footer />
    </div>
  );
};
