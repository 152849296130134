import React from 'react';
import {
  Landing,
  License,
  NotFound,
  Pricing,
  PrivacyPolicy,
  Referral,
  TermsOfUse,
} from 'features/misc';
import { useRoutes } from 'react-router-dom';

import { publicRoutes } from './public';

export const AppRoutes = () => {
  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-use', element: <TermsOfUse /> },
    { path: '/pricing', element: <Pricing /> },
    { path: '/referral', element: <Referral /> },
    { path: '/license', element: <License /> },
    { path: '*', element: <NotFound /> },
  ];

  const element = useRoutes([...publicRoutes, ...commonRoutes]);

  return <>{element}</>;
};
