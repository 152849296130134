import { API_URL } from 'config';

const storagePrefix = 'msw-db';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  getRefreshToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}refreshtoken`)
    );
  },
  setToken: token => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  setErrorMessage: error => {
    window.localStorage.setItem('errorMessage', JSON.stringify(error));
  },
  setErrorResponse: error => {
    window.localStorage.setItem('errorResponse', JSON.stringify(error));
  },
  setActivePortfolio: portfolio_id => {
    window.localStorage.setItem(
      `selectedPortfolioId`,
      JSON.stringify(portfolio_id)
    );
  },
  setActivePortfolioName: portfolio => {
    window.localStorage.setItem(`selectedPortfolio`, JSON.stringify(portfolio));
  },
  getActivePortfolioName: () => {
    return JSON.parse(window.localStorage.getItem('selectedPortfolioName'));
  },
  getErrorMessage: () => {
    return JSON.parse(window.localStorage.getItem('errorMessage'));
  },
  getErrorResponse: () => {
    return JSON.parse(window.localStorage.getItem('errorResponse'));
  },
  clearError: () => {
    window.localStorage.removeItem(`errorMessage`);
    window.localStorage.removeItem(`errorResponse`);
  },
  getActivePortfolio: () => {
    return JSON.parse(window.localStorage.getItem(`selectedPortfolioId`));
  },
  setRefreshToken: token => {
    window.localStorage.setItem(
      `${storagePrefix}refreshtoken`,
      JSON.stringify(token)
    );
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  clearStorage: () => {
    window.localStorage.clear();
  },
  refreshToken: () => {
    const token = storage.getRefreshToken(); // Get the current token from localStorage

    // Check if token exists
    if (token) {
      // TODO: Implement your token refresh logic here

      // Example: Make an API call to refresh the token
      // replace `YOUR_REFRESH_API_ENDPOINT` with the actual API endpoint
      fetch(API_URL + '/auth/login/refresh/', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(newToken => {
          // Update the token in localStorage
          storage.setToken(newToken);
          console.log('Token refreshed successfully!');
        })
        .catch(error => {
          console.error('Failed to refresh token:', error);
        });
    } else {
      console.log('No token found to refresh.');
    }
  },
};

export default storage;
